:root {
  --falcon-card-link: #2020ec;
}
.header-link {
  color: #2c7be5 !important;
  text-decoration: none;
}
.header-link:hover {
  color: #2362b7 !important;
}
.card-link {
  color: #2020ec !important;
}
.hover-grey:hover {
  color: lightgray !important;
  cursor: pointer;
}
.ReactFlagsSelect-module_selectBtn__19wW7 {
  padding: 0px 10px !important;
}
.container-xxl {
  max-width: none !important;
}
@media (min-width: 1200px) {
  .container-xxl {
    max-width: 100% !important;
  }
}

.content-border {
  border: 2px solid #007bff;
  padding: 50px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.custom-toast-error {
  background-color: #df1036 !important;
}

.custom-toast-success {
  background-color: #068550 !important;
}

.grid-template-columns-2 {
  column-gap: 2rem;
  row-gap: 0.2rem;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-template-areas:
    'area-0 area-5'
    'area-1 area-6'
    'area-2 area-7'
    'area-3 area-8'
    'area-4 area-9';
}

.cutted-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.content-table {
  height: 50px;
}

.hover {
  transition: all 0.4s;
}

.hover:hover {
  transition: all 0.4s;
  opacity: 0.5;
}

.active-page {
  background: linear-gradient(
    179deg,
    rgb(55, 81, 244) 10%,
    rgb(53, 101, 242) 40%,
    rgb(20, 51, 246) 50%
  );
  border: none;
  outline: none;
}

.form-select:disabled {
  background-color: #fff !important;
  opacity: 0.5 !important;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1em;
}

.b-none {
  border: none !important;
}

.loading-approval {
  position: absolute;
  inset: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #000;
  opacity: 0.3;
}

.simplebar-mask {
  overflow: visible !important;
}

.plan-wrap {
  position: relative;
  display: flex;
  transform: translateY(-30px);
  height: fit-content;
  width: auto;
  overflow: hidden;
}

.react-pdf__Page__textContent {
  display: none;
}

.react-pdf__Page__canvas {
  width: 100% !important;
  height: 100% !important;
}

.height-800 {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
