.footer-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer-nav-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer-nav-link {
  padding-right: 8px;
  padding-left: 8px;
  margin: 0px 16px 0px 16px;
}
@media only screen and (max-width: 568px) {
  .footer-navbar {
    flex-direction: column;
  }
  .footer-nav-items {
    display: flex;
    justify-content: space-between !important;
    align-items: center;
    width: 100%;
  }
  .nav .footer-nav-link {
    padding-right: 0px;
    padding-left: 0px;
    margin: 0px;
  }
}
