.breadcrumbs-wrapper{
    display: flex
}
@media (max-width: 575.98px) {
    .breadcrumbs-wrapper {
      flex-wrap: wrap;
    }
  
    /* .breadcrumbs-wrapper > * {
      margin-bottom: 0.5rem;
    } */
  }