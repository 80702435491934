/* Contact Us form  */
.custom-input-mask {
    border: none; /* Remove the borders */
    outline: none; /* Remove the outline when focused */
    box-shadow: none; /* Remove the box-shadow when focused */
    padding: 5px 16px;
  }
  
  .custom-input-mask:focus {
    border: none; /* Remove the borders when focused */
    box-shadow: none; /* Remove the box-shadow when focused */
  }